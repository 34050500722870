<template>
  <div>
    <v-app-bar
        color="#ffffff"
        fixed
        style="z-index: 190;"
        height="56"
        elevation="0"
    >
      <v-app-bar-nav-icon
          @click="toggleNavTab(true)"
          class="hamburger-icon"
      ></v-app-bar-nav-icon>
      <v-toolbar-title class="heading">
        <router-link to="/">
          <img src="@/assets/image/header-bkash-icon.png" class="header-icon"/>
        </router-link>
        <div class="vl"></div>
        <p class="header-text">Business Dashboard</p>
      </v-toolbar-title>

      <v-spacer></v-spacer>
      <Inbox></Inbox>
      <v-menu left bottom>
        <template v-slot:activator="{ on, attrs }">
          <div
              class="circle"
              :class="{ 'circle-no-fill': pgwLogo }"
              v-bind="attrs"
              v-on="on"
          >
            <img class="logo-as-icon" :src="pgwLogo" alt=""/>
            <v-icon size="22" left>mdi-menu-down</v-icon>
          </div>
        </template>

        <v-list>
          <v-list-item @click="goToAccountInfo">
            <v-list-item-title>Account Info</v-list-item-title>
          </v-list-item>
          <v-list-item @click="initChangeLogo">
            <v-list-item-title>Change Logo</v-list-item-title>
          </v-list-item>
          <v-list-item @click="goToChangePassword">
            <v-list-item-title>Change Password</v-list-item-title>
          </v-list-item>
          <v-list-item @click="showReleaseNoteModal">
            <v-list-item-title>Release Note</v-list-item-title>
          </v-list-item>
          <v-list-item to="/faq-support">
            <v-list-item-title>FAQ &amp; Support</v-list-item-title>
          </v-list-item>
          <v-list-item @click="handleLogout">
            <v-list-item-title>Logout</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>

    <v-navigation-drawer v-model="drawer" app temporary class="nav-drawer">
      <div class="heading-container">
        <v-toolbar-title class="heading">
          <router-link to="/">
            <img src="@/assets/image/headerbkash-icon-mobile.svg"/>
          </router-link>
          <div class="vl"></div>
          <p class="header-text">Business Dashboard</p>
        </v-toolbar-title>
        <v-icon
            class="close-icon"
            size="28"
            @click="toggleNavTab(false)"
        >
          mdi-close
        </v-icon>
      </div>

      <v-list class="links-list">
        <!-- <nav-link to="/dashboard" exact>Home</nav-link> -->
        <router-link to="/dashboard" @click.native="toggleNavTab(false)">
          <v-list-item>
            <v-list-item-title class="link-title">
              Home
            </v-list-item-title>
          </v-list-item>
        </router-link>
        <hr class="item-divider">
        <router-link to="/payment-links">
          <v-list-item @click="toggleNavTab(false)">
            <v-list-item-title class="link-title">
              Payment Links
            </v-list-item-title>
          </v-list-item>
        </router-link>
        <hr class="item-divider">
        <router-link to="/transaction-report">
          <v-list-item @click="toggleNavTab(false)">
            <v-list-item-title class="link-title">
              Transactions
            </v-list-item-title>
          </v-list-item>
        </router-link>
        <hr class="item-divider">
        <router-link to="/order-management">
          <v-list-item @click="toggleNavTab(false)">
            <v-list-item-title class="link-title">
              Orders
            </v-list-item-title>
          </v-list-item>
        </router-link>
        <hr class="item-divider">
        <router-link to="/merchant-quest" v-if="isMerchantOnMerchantQuest">
          <v-list-item @click="toggleNavTab(false)">
            <v-list-item-title class="link-title">
              Quests
            </v-list-item-title>
          </v-list-item>
        </router-link>
        <hr class="item-divider" v-if="isMerchantOnMerchantQuest">
        <router-link to="/referandearn">
          <v-list-item @click="toggleNavTab(false)">
            <v-list-item-title class="link-title">
              Referrals
            </v-list-item-title>
          </v-list-item>
        </router-link>
        <hr class="item-divider">
        <!-- <v-list-group no-action @click.stop class="services">
          <template v-slot:activator>
            <v-list-item-title class="link-title"
            >Services
            </v-list-item-title
            >
          </template>

          <v-list-item to='/send-money' class="dropdown-link">
            <v-list-item-title class="link-title" @click="toggleNavTab(false)">Send Money
            </v-list-item-title>
          </v-list-item>

          <v-list-item to='/payment' class="dropdown-link">
            <v-list-item-title class="link-title" @click="toggleNavTab(false)">Merchant Payment
            </v-list-item-title>
          </v-list-item>

          <v-list-item to='/refund' class="dropdown-link">
            <v-list-item-title class="link-title" @click="toggleNavTab(false)">Refund</v-list-item-title>
          </v-list-item>
        </v-list-group> -->
        <!-- <hr class="item-divider"> -->

      </v-list>
      <!--      this section will be active after enabling 'bn' localization for Dashboard-->
      <!-- <p class="localization">
        <span class="en" :class="{'active-lang': isLangIsEn}">Eng</span> |
        <span class="bn" :class="{'active-lang': !isLangIsEn}">বাংলা</span></p> -->
    </v-navigation-drawer>
  </div>
</template>

<script>
import Inbox from "../inbox/Inbox.vue";

export default {
  data() {
    return {
      isInfoActive: false,
      isTransferActive: false,
      drawer: false,
      lang: this.$i18n.locale || 'বাংলা'
    };
  },
  components: {
    Inbox
  },
  computed: {
    pgwLogo() {
      return this.$store.getters.getPGWLogo;
    },
    userName() {
      return this.$store.getters.getUserName;
    },
    isSmallDisplay() {
      return this.$vuetify.breakpoint.name.match(/sm|xs/);
    },
    releaseNote() {
      return this.$store.getters.getReleaseNote;
    },
    facebookGroupLink() {
      return process.env.VUE_APP_FACEBOOK_GROUP_URL || "";
    },
    isLangIsEn() {
      return this.$i18n.locale === 'eng';
    },
    isMerchantOnMerchantQuest() {
      return this.$store.getters.getMerchantOnMerchantQuest;
    }
  },
  methods: {
    handleLogout() {
      this.$cppClient
          .post("logout", {
            idToken: this.$store.getters.getIdToken,
          })
          .then(() => {
            this.$store.commit('clearPageState');
            this.$store.commit("logoutUser");
            this.$router.push({name: "sign-in"});
          })
          .catch(() => {
            this.$store.commit('clearPageState');
            this.$store.commit("logoutUser");
            this.$router.push({name: "sign-in"});
          });
    },
    goToChangePassword() {
      this.$router.push("/change-password");
    },
    goToAccountInfo() {
      this.$router.push("/account-info");
    },
    toLimitsFees() {
      this.$router.push({name: "ac-limits-fees"});
    },
    toFaqSupport() {
      this.$router.push({name: "faq-support"});
    },
    initChangeLogo() {
      this.$store.commit("setShowLogoChangeDialog", true);
    },
    showReleaseNoteModal() {
      if (
          this.releaseNote.isPublished &&
          this.releaseNote.featureList &&
          this.releaseNote.featureList.length > 0
      ) {
        return this.$eventBus.$emit("showReleaseNoteDialog");
      }

      this.$feedback.showSubtleErrorToast("Nothing new to show !");
    },
    toggleNavTab(drawerVal) {
      this.drawer = !!drawerVal
    }
  },
  watch: {
    $route(to) {
      this.isInfoActive =
          to.name === "ac-limits-fees" || to.name === "faq-support";
      this.isTransferActive = to.name === "payout" || to.name === "refund";
    },
  },

};
</script>

<style scoped>
.nav-drawer {
  z-index: 200 !important;
  width: 85% !important;
  max-width: 301px !important;
  min-width: 279px !important;
  height: 100% !important;
  padding: 20px 0px !important
}

.link-title {
  font-size: 14px !important;
  color: #464646 !important;
  letter-spacing: .5px;
  font-weight: 500;
}

.dropdown-link {
  padding-left: 16px !important;
}

.heading-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}

.nav-drawer .heading-container {
  padding-left: 16px;
  padding-right: 10px;
}

.close-icon {
  color: #777777 !important;
}

.header-icon {
  width: 66px;
}

.services {
  color: #464646 !important;
}

.vl {
  margin-left: 6px;
  background-color: #dedede;
  height: 17px;
  margin-right: 8px;
}

.header-text {
  font-size: 10px;
  font-weight: normal;
  color: #a7a7a7;
  margin-bottom: 0;
}


.item-divider {
  margin: 5px 0 !important;
  border: 1px solid #DEDEDE;
}

.active-lang {
  color: #E2136E;
  font-weight: 500;
}

.circle {
  margin-right: -6px;
}

.hamburger-icon >>> i {
  font-size: 30px !important;
  color: #777777 !important;
  margin-top: -5px;
}

.heading {
  display: flex;
  align-content: center;
  align-items: center;
  padding-left: 0 !important;
}

.localization {
  text-align: center;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  margin-bottom: 24px;
  font-size: 12px;
}

.logo-as-icon {
  width: 25px;
  height: 25px;
  border-radius: 50%;
}
</style>