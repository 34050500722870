<template>
  <div>
    <nav-bar v-if="shouldShowNavBar"></nav-bar>
    <router-view></router-view>
    <v-dialog
        v-model="showLogoChangeDialog"
        persistent
        width="535"
    >
      <v-card height="100%">
        <logo-upload :notifyDialogClose="handleDialogClosed" :isVisible="showLogoChangeDialog"></logo-upload>
      </v-card>
    </v-dialog>
    <release-note-dialog></release-note-dialog>
  </div>
</template>

<script>
import NavBar from "@/components/common/NavBar";
import LogoUpload from "@/components/dashboard/LogoUpload";
import ReleaseNoteDialog from './dashboard/ReleaseNoteDialog.vue';

const navBarHiddenOnRoutes = [
  "referandearn",
  "referralreport",
  "refer",
  "refer-form"
];

export default {
  components: {
    NavBar,
    LogoUpload,
    ReleaseNoteDialog,
  },
  updated() {
    if (this.$store.getters.getUserName) {
      document.title = process.env.VUE_APP_PAGE_TITLE + ' | ' + this.$store.getters.getUserName;
    } else {
      document.title = (this.$route.name === 'landing' ? process.env.VUE_APP_PAGE_LANDING_PAGE_TITLE : process.env.VUE_APP_PAGE_TITLE + ' | ' + this.humanize(this.$route.name));
    }
  },
  mounted() {
    if (this.$store.getters.getUserName) {
      document.title = process.env.VUE_APP_PAGE_TITLE + ' | ' + this.$store.getters.getUserName;
    } else {
      document.title = (this.$route.name === 'landing' ? process.env.VUE_APP_PAGE_LANDING_PAGE_TITLE : process.env.VUE_APP_PAGE_TITLE + ' | ' + this.humanize(this.$route.name));
    }
  },
  computed: {
    shouldShowNavBar() {
      return this.$store.getters.isAuthenticated && navBarHiddenOnRoutes.indexOf(this.$route.name) < 0 && this.$route.name !== 'landing';
    },
    showLogoChangeDialog: {
      get: function () {
        return this.$store.getters.getShowLogoChangeDialog;
      },
      set: function () {
        this.$store.commit("setShowLogoChangeDialog", false);
      }
    }
  },
  methods: {
    humanize(str) {
      return str
          .replace(/^[\s_]+|[\s_]+$/g, '')
          .replace(/[_\s]+/g, ' ')
          .replace(/-/g, ' ')
          .replace(/^[a-z]/, function (m) {
            return m.toUpperCase();
          });
    },

    handleDialogClosed() {
      this.showLogoChangeDialog = false;
    }
  }

}
</script>
