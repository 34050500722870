<template>
  <v-dialog v-model="dialog" width="700" height="600">
    <template v-slot:activator="{ attrs }">
      <div v-bind="attrs" v-on:click="dialog = true" class="MessageCard">
        <video v-if="isVideo(image)" class="MessageCard__video">
          <source :src="image" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <img v-else class="MessageCard__image" :src="image" />
        <div class="MessageCard__content">
          <div class="MessageCard__title">{{ title }}
            <v-badge class="MessageCard__title--badge" inline :value="badgeValue" color="#E2136E" dot>
            </v-badge>
          </div>
          <div class="MessageCard__subtitle">{{ subtitle }}</div>
          <div class="MessageCard__subtitle">{{ getDate(sendOnEpoch) }}</div>
        </div>
      </div>
    </template>
    <v-card background-color="white">
      <section class="header-group">
        <span>{{ cardTitle }}</span>
        <v-icon
          class="close-icon"
          color="#D0021B"
          size="20"
          @click="dialog = false"
        >
          mdi-close
        </v-icon>
      </section>
      <v-divider></v-divider>
      <div style="padding: 20px;">
        <video v-if="isVideo(image)" width="100%" controls>
          <source :src="image" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <v-img v-else width="100%" contain :src="image"></v-img>
        <div style="margin-bottom: 24px;">
          <div class="card-title">{{ title }}</div>
          <div class="card-subtitle">{{ subtitle }}</div>
          <br />
          <div class="card-paragraph" v-html="paragraph"></div>
        </div>
        <v-btn
          v-if="hiddenMetaData"
          outlined
          color="#E2136E"
          class="cta-btn"
          @click="goToLink"
        >
          {{ hiddenMetaData }}
        </v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from "moment";

export default {
  name: "MessageCard",
  props: [
    "badgeValue",
    "title",
    "subtitle",
    "image",
    "paragraph",
    "hiddenMetaData",
    "cardTitle",
    "notificationCtaLink",
    "sendOnEpoch"
  ],
  data() {
    return {
      dialog: false,
    };
  },
  created() {},
  methods: {
    goToLink() {
      window.open(this.notificationCtaLink, "_blank");
    },
    isVideo(path) {
      return path.split(".").pop() === "mp4";
    },
    getDate(epoch) {
      return moment(epoch).format('hh:mm A DD/MM/YYYY');
    },
  },
};
</script>

<style scoped lang="scss">
.MessageCard {
  // width: 328px;
  // height: 226px;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 8px;
  background-color: white;
  padding: 12px;

  @include breakPoint(webS) {
    width: 450px;
    height: 120px;
    flex-direction: row;
    margin-bottom: 0;
    padding: 0;
  }

  &__video {
    // height: 140px;
    width: 100%;
    margin-bottom: 8px;

    @include breakPoint(webS) {
      height: 80px;
      width: 120px;
      margin-bottom: 0;
    }
  }

  &__image {
    width: 100%;
    object-fit: cover;
    margin-bottom: 8px;

    @include breakPoint(webS) {
      height: 80px;
      max-width: 120px;
      margin-bottom: 0;
    }
  }

  &__content {
    margin-left: 0;
    width: 100%;

    @include breakPoint(webS) {
      margin-left: 16px;
    }
  }

  &__title {
    color: #464646;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    justify-content: space-between;
    display: flex;

    @include breakPoint(webS) {
      font-size: 18px;
      font-weight: 500;
      overflow: hidden;
      white-space: nowrap;
    }

    &--badge {
      @include breakPoint(webS) {
        display: none;
      }
    }
  }

  &__subtitle {
    margin-bottom: 2px;
    color: #777;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    @include breakPoint(webS) {
    }
  }

  &__datetime {
    @include breakPoint(webS) {
    }
  }
}
.header-group {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #464646;
  font-weight: 500;
  padding: 16px 20px;
}
.card-title {
  color: #464646;
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 12px;
}
.card-subtitle {
  color: #777;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.card-paragraph {
  color: #777;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
</style>
