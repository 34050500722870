<template>
  <div class="text-center">
    <v-dialog
      v-model="dialog"
      content-class="custom-dialog"
      persistent
      width="855"
    >
      <v-card>
        <v-container
          class="container-non-mobile"
          style="padding: 0; overflow: hidden;"
        >
          <v-btn
            icon
            style="position: absolute; right: 5px; z-index: 5; top: 5px;"
            @click="dialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-row style="padding: 0;">
            <v-col cols="4" style="background: #e2136e;">
              <div class="release-note-title" style="font-weight: bold;">
                Release Note
              </div>
              <div class="release-note-sub-title" style="margin-bottom: 15px;">
                Ver. {{ version }}
              </div>
              <div class="major-feature" style="margin-left: 25px;">
                Major Features
              </div>
              <v-virtual-scroll
                :items="featureList"
                class="feature-list"
                height="150"
                item-height="25"
              >
                <template v-slot:default="{ index, item }">
                  <v-list-item-content>
                    <v-list-item-title>
                      <span v-if="index + 1 === page" style="color: #ffcb15;">{{
                        item.name
                      }}</span>
                      <span v-else>{{ item.name }}</span>
                    </v-list-item-title>
                  </v-list-item-content>
                </template>
              </v-virtual-scroll>
              <div style="text-align: left; margin-top: 16px">
                <p class="release-date">Date: {{ creationDate }}</p>
              </div>
            </v-col>
            <v-col
              align="center"
              cols="8"
              style="background-color: white; padding: 10px;"
            >
              <v-stepper v-model="page" elevation="0" value="page">
                <v-stepper-content
                  v-for="(step, n) in featureList"
                  :key="`${n}-feature`"
                  :step="n + 1"
                >
                  <v-row
                    no-gutters
                    style="margin-bottom: 10px; margin-right: 20px; margin-top: 10px; margin-left: 5px;"
                  >
                    <v-img
                      :src="featureList[n].image"
                      class="feature-image"
                    ></v-img>
                  </v-row>
                  <v-row justify="space-between" no-gutters>
                    <div></div>
                    <v-btn
                      v-if="featureList.length > 1 && page < featureList.length"
                      class="modal-button"
                      color="#e2136e"
                      style="color: #FFFFFF; margin-left: 5px;"
                      @click="page = page + 1"
                    >
                      Next
                    </v-btn>
                    <v-btn
                      v-if="
                        featureList.length > 1 && page === featureList.length
                      "
                      class="modal-button"
                      color="#e2136e"
                      style="color: #FFFFFF; margin-left: 5px;"
                      @click="page = 1"
                    >
                      Previous
                    </v-btn>
                  </v-row>
                </v-stepper-content>
              </v-stepper>
            </v-col>
          </v-row>
        </v-container>
        <v-container
          class="container-mobile"
          style="padding: 0; overflow: hidden;"
        >
          <v-btn
            icon
            dark
            style="position: absolute; right: 5px; z-index: 5; top: 5px;"
            @click="dialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-row style="padding: 0; margin-left: 0; margin-right: 0; background: #e2136e;">
            <v-row
              no-gutters
              style="width: 100%; justify-content: center; margin-left: 0; margin-right: 0;"
            >
              <div class="release-note-title" style="font-weight: bold;">
                Release Note
              </div>
            </v-row>
            <v-row
              no-gutters
              style="width: 100%; justify-content: center; margin-left: auto; margin-right: auto;"
            >
              <div
                class="release-note-sub-title"
                style="margin-bottom: 16px;"
              >
                Ver. {{ version }}
              </div>
            </v-row>
            <v-row
              no-gutters
              style="width: 100%; justify-content: center; background-color: #e2136e; margin-top: -30px; margin-left: 0; margin-right: 0;"
            >
              <v-stepper
                v-model="page"
                elevation="0"
                style="background-color: #e2136e;"
                value="page"
              >
                <v-stepper-content
                  v-for="(step, n) in featureList"
                  :key="`${n}-feature`"
                  :step="n + 1"
                  style="padding-left: 0; padding-right: 0;"
                >
                  <v-row no-gutters style="padding: 0 4px;">
                    <v-img
                      :src="featureList[n].image"
                      class="feature-image"
                    ></v-img>
                  </v-row>
                </v-stepper-content>
              </v-stepper>
            </v-row>
          </v-row>
          <v-row style="padding: 0; margin-left: 0; margin-right: 0;">
            <v-col
              align="center"
              cols="12"
              style="background-color: white; padding: 10px;"
            >
              <v-row
                justify="space-between"
                no-gutters
                style="margin-left: 5px; margin-bottom: 5px;"
              > 
                <div class="major-feature" style="margin-top: 5px;">
                  Major Features
                </div>
                <v-btn
                  v-if="featureList.length > 1 && page < featureList.length"
                  class="modal-button"
                  color="#e2136e"
                  style="color: #FFFFFF; margin-left: 5px;"
                  @click="page = page + 1"
                >
                  Next
                </v-btn>
                <v-btn
                  v-if="featureList.length > 1 && page === featureList.length"
                  class="modal-button"
                  color="#e2136e"
                  style="color: #FFFFFF; margin-left: 5px;"
                  @click="page = 1"
                >
                  Previous
                </v-btn>
              </v-row>
              <v-virtual-scroll
                :items="featureList"
                class="feature-list"
                height="100"
                item-height="25"
              >
                <template v-slot:default="{ index, item }">
                  <v-list-item-content>
                    <v-list-item-title>
                      <span v-if="index + 1 === page" style="color: #ffcb15;">{{
                        item.name
                      }}</span>
                      <span v-else>{{ item.name }}</span>
                    </v-list-item-title>
                  </v-list-item-content>
                </template>
              </v-virtual-scroll>
              <div
                style="text-align: left; margin-bottom: 10px; margin-left: -5px;margin-top: 12px"
              >
                <p class="release-date">Date: {{ creationDate }}</p>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  name: "ReleaseNoteDialog",

  data() {
    return {
      dialog: false,
      releaseId: "release-v1.0.0",
      version: "",
      creationDate: "",
      page: 1,
      featureList: [],
    };
  },
  mounted() {
    this.$eventBus.$on("showReleaseNoteDialog", () => {
      let releaseNote = this.$store.getters.getReleaseNote;
      this.releaseId = releaseNote.releaseId;
      this.creationDate = releaseNote.creationDateFormatted;
      this.featureList = releaseNote.featureList;
      this.version = releaseNote.version;
      this.dialog = true;
    });
  },
  methods: {
    closeDialog() {
      this.pageId = null;
      this.productId = null;
      this.dialog = false;
    },
  },
};
</script>
<style lang="scss">
.container-mobile {
  display: none;
}

.release-note-title {
  margin: 0 0 0 25px;
  font-family: Arial;
  font-size: 30px;
  font-style: normal;
  line-height: 1.72;
  text-align: left;
  color: #fff;
}

.release-note-sub-title {
  margin: 0 0 0 25px;
  font-family: Arial;
  font-size: 28px;
  font-style: normal;
  line-height: 1.72;
  text-align: left;
  color: #fff;
}

.feature-image {
  background-size: contain;
  transition: transform 0.2s;
  overflow: hidden;
  cursor: pointer;
  text-align: center;
  align-items: center;
}

.custom-dialog.v-dialog {
  border-radius: 25px;
}

.modal-button {
  width: 96px;
  min-width: 64px;
  height: 36px;
}

.major-feature {
  font-family: Arial;
  font-size: 25px;
  font-weight: normal;
  font-stretch: normal;
  text-align: left;
  color: #fff;
  margin-bottom: 10px;
}

.feature-list {
  font-family: Arial;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  text-align: left;
  color: #fff;
  margin-left: 25px;
}

.release-date {
  font-size: 20px;
  color: #fff;
  margin-left: 25px;
  word-break: break-word;
}

.v-stepper {
  background: none;
  box-shadow: none;
}

@media only screen and (max-width: 560px) {
  .container-non-mobile {
    display: none;
  }

  .container-mobile {
    display: block;
  }

  .release-note-title {
    font-size: 32px;
    text-align: center;
    margin: 0;
  }

  .release-note-sub-title {
    font-size: 28px;
    text-align: center;
    margin: 0;
  }

  .major-feature {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 0;
  }

  .feature-list {
    font-size: 10px;
  }

  .modal-button {
    width: 85px;
    min-width: 32px;
    height: 16px;
  }

  .major-feature {
    font-family: Arial;
    font-size: 20px;
    text-align: left;
    color: #e2136e;
    margin-bottom: 10px;
  }

  .feature-list {
    font-size: 16px;
    text-align: left;
    color: #e2136e;
    margin-left: 15px;
  }

  .release-date {
    font-size: 11px;
    color: #e2136e;
    margin-left: 20px;
  }
}

@media only screen and (max-width: 360px) {
  .release-note-title {
    font-size: 27px;
  }
}
</style>
