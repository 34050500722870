<template>
  <div class="ribbon-container">
    <mobile-nav-bar v-if="isSmallDisplay"></mobile-nav-bar>
    <web-nav-bar v-else></web-nav-bar>
  </div>
</template>

<script>

import WebNavBar from "@/components/common/navigation/WebNavBar";
import MobileNavBar from "@/components/common/navigation/MobileNavBar";

export default {
  components: {
    WebNavBar,
    MobileNavBar
  },
  computed: {
    isSmallDisplay() {
      return this.$vuetify.breakpoint.width <= 1000;
    }
  },
}
</script>
