<template>
  <div>
    <v-menu
      bottom
      origin="center center"
      transition="scale-y-transition"
      offset-y
      :close-on-content-click="false"
      v-if="!isSmallDisplay"
    >
      <template v-slot:activator="{ on, attrs }">
        <div class="user-layout" v-bind="attrs" v-on="on">
          <v-badge color="#E2136E" dot :value="offerCount + noticeCount" overlap>
            <v-icon color="#777777">mdi-email-outline</v-icon>
          </v-badge>
        </div>
      </template>
      <div style="background-color: white; padding: 15px;">
        <span style="font-size: 24px; font-weight: 500px; padding-top: 20px;"
          >Inbox</span
        >

        <v-tabs v-model="tab">
          <v-tab
            ><v-badge :value="offerCount" color="#E2136E" dot
              >Offers</v-badge
            ></v-tab
          >
          <v-tab
            ><v-badge :value="noticeCount" color="#E2136E" dot
              >Notices</v-badge
            ></v-tab
          >
        </v-tabs>
        <v-divider></v-divider>
        <v-tabs-items v-model="tab">
          <v-tab-item>
            <div class="Inbox__content">
              <template v-for="(item, i) in offers">
                <v-list-item :key="i + item.title" @click="readMessage(i)">
                  <v-badge inline :value="readOffers[i]" color="#E2136E" dot>
                    <MessageCard
                      :title="item.title"
                      :image="item.mediaLink"
                      :subtitle="item.subtitle"
                      :paragraph="item.paragraph"
                      :hiddenMetaData="item.hiddenMetaData"
                      :notificationCtaLink="item.notificationCtaLink"
                      :sendOnEpoch="item.sendOnEpoch"
                      cardTitle="Offer Details"
                    >
                    </MessageCard>
                  </v-badge>
                </v-list-item>
                <v-divider :key="i"></v-divider>
              </template>
              <div v-if="offers.length < 1" class="Inbox__content--empty">You don't have any offer yet</div>
            </div>
          </v-tab-item>
          <v-tab-item>
            <div class="Inbox__content">
              <template v-for="(item, i) in notices">
                <v-list-item :key="i + item.title" v-on:click="readMessage(i)">
                  <v-badge inline :value="readNotices[i]" color="#E2136E" dot>
                    <MessageCard
                      :title="item.title"
                      :image="item.mediaLink"
                      :subtitle="item.subtitle"
                      :paragraph="item.paragraph"
                      :hiddenMetaData="item.hiddenMetaData"
                      :notificationCtaLink="item.notificationCtaLink"
                      :sendOnEpoch="item.sendOnEpoch"
                      cardTitle="Notice"
                    ></MessageCard>
                  </v-badge>
                </v-list-item>
                <v-divider :key="i"></v-divider>
              </template>
              <div v-if="notices.length < 1" class="Inbox__content--empty">You don't have any notice yet</div>
            </div>
          </v-tab-item>
        </v-tabs-items>
      </div>
    </v-menu>
    <v-dialog
      v-model="dialog1"
      fullscreen
      hide-overlay
      transition="dialog-top-transition"
      scrollable
      v-if="isSmallDisplay"
    >
      <template v-slot:activator="{ on, attrs }">
        <div class="user-layout" v-bind="attrs" v-on="on">
          <v-badge color="#E2136E" dot :value="offerCount + noticeCount" overlap>
            <v-icon color="#777777">mdi-email-outline</v-icon>
          </v-badge>
        </div>
      </template>
      <v-card color="#F5F5F5">
        <v-toolbar flat dark color="grey" max-height="56">
          <v-btn icon dark @click="dialog1 = false">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          <span style="font-size: 24px; font-weight: 500px;">Inbox </span>
        </v-toolbar>
        <div style="background-color: #F5F5F5; padding: 15px;">
          <v-tabs v-model="tab" fixed-tabs background-color="white">
            <v-tab
              ><v-badge :value="offerCount" color="#E2136E" dot
                >Offers</v-badge
              ></v-tab
            >
            <v-tab
              ><v-badge :value="noticeCount" color="#E2136E" dot
                >Notices</v-badge
              ></v-tab
            >
          </v-tabs>
          <br />
          <v-tabs-items v-model="tab" style="background-color: #F5F5F5;">
            <v-tab-item>
              <template v-for="(item, i) in offers">
                <div
                  :key="i + item.title"
                  @click="readMessage(i)"
                  style="margin-bottom: 10px;"
                >
                  <MessageCard
                    :badgeValue="readOffers[i]"
                    :title="item.title"
                    :image="item.mediaLink"
                    :subtitle="item.subtitle"
                    :paragraph="item.paragraph"
                    :hiddenMetaData="item.hiddenMetaData"
                    :notificationCtaLink="item.notificationCtaLink"
                    :sendOnEpoch="item.sendOnEpoch"
                    cardTitle="Offer Details"
                  >
                  </MessageCard>
                </div>
              </template>
              <div v-if="offers.length < 1" class="Inbox__content--empty">You don't have any offer yet</div>
            </v-tab-item>
            <v-tab-item>
              <template v-for="(item, i) in notices">
                <div
                  :key="i + item.title"
                  v-on:click="readMessage(i)"
                  style="margin-bottom: 10px;"
                >
                  <MessageCard
                    :badgeValue="readNotices[i]"
                    :title="item.title"
                    :image="item.mediaLink"
                    :subtitle="item.subtitle"
                    :paragraph="item.paragraph"
                    :hiddenMetaData="item.hiddenMetaData"
                    :notificationCtaLink="item.notificationCtaLink"
                    :sendOnEpoch="item.sendOnEpoch"
                    cardTitle="Notice"
                  ></MessageCard>
                </div>
              </template>
              <div v-if="notices.length < 1" class="Inbox__content--empty">You don't have any notice yet</div>
            </v-tab-item>
          </v-tabs-items>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import MessageCard from "./MessageCard.vue";

export default {
  name: "Inbox",
  data() {
    return {
      tab: 0,
      items: ["offers", "notices"],
      dialog1: false,
      notices: [],
      offers: [],
      readOffers: [],
      readNotices: [],
      offerCount: 0,
      noticeCount: 0,
    };
  },
  created() {
    this.fetchMessage();
  },
  methods: {
    refreshClicked() {
      this.lastAttempted = Date.now();
      this.$emit("refresh-request");
    },
    async fetchMessage() {
      if (!this.$store.getters.isAuthenticated) { return; }
      if (!this.$store.getters.hasProkashToken) {
        console.debug("Prokash token is not available");
        return;
      }

      console.log("fetch message");
      let inbox = localStorage.getItem("inbox");
      if (!inbox) {
        inbox = [];
        localStorage.setItem("inbox", JSON.stringify(inbox));
      }

      try {
        let response = await this.$cppClientProkashServices.postProkash(
          "member/get-messages",
          {}
        );
        let { groups } = response.data;
        console.log(groups);
        this.notices = groups[0].messages;
        this.offers = groups[1].messages;
      } catch (ex) {
        let { response } = ex;
        console.log(response);
      }

      let noticeArr = [];
      this.notices.forEach((item) => {
        let id = inbox.includes(item.id);
        if (!id) {
          noticeArr.push(1);
        } else {
          noticeArr.push(0);
        }
      });
      this.readNotices = noticeArr;

      let offerArr = [];
      this.offers.forEach((item) => {
        let id = inbox.includes(item.id);
        if (!id) {
          offerArr.push(1);
        } else {
          offerArr.push(0);
        }
      });
      this.readOffers = offerArr;

      this.noticeCount = this.readNotices.includes(1) ? 1 : 0;
      this.offerCount = this.readOffers.includes(1) ? 1 : 0;

      if (this.$store.getters.isAuthenticated)
        setTimeout(() => {
          this.fetchMessage();
        }, 120000);
    },
    readMessage(index) {
      var list = localStorage.getItem("inbox");
      let inbox = JSON.parse(list);

      if (this.tab === 1) {
        if (!inbox.includes(this.notices[index].id)) {
          inbox.push(this.notices[index].id);
        }
        this.$set(this.readNotices, index, 0);
        this.noticeCount = this.readNotices.includes(1) ? 1 : 0;
      } else {
        if (!inbox.includes(this.offers[index].id)) {
          inbox.push(this.offers[index].id);
        }
        this.$set(this.readOffers, index, 0);
        this.offerCount = this.readOffers.includes(1) ? 1 : 0;
      }

      localStorage.setItem("inbox", JSON.stringify(inbox));
      // this.tab === "notices"? this.$set(this.readNotices, index, 0) : this.$set(this.readOffers, index, 0);
    },
  },
  components: { MessageCard },
  computed: {
    isSmallDisplay() {
      return this.$vuetify.breakpoint.width <= 1000;
    },
  },
};
</script>

<style scoped lang="scss">
.user-layout {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-self: center;
}

.v-list-item {
  padding: 0;
}

.Inbox__content {
  max-height: 400px;
  overflow: scroll;

  &--empty {
    padding-top: 10px;
    text-align: center;
    font-size: 18px;
    color: #777;
    font-family: Roboto;

    @include breakPoint(webS) {
      width: 450px;
    }
  }
}
</style>
