<template>
  <router-link :to="to" v-slot="{ href, route, navigate, isActive, isExactActive }">
    <a :href="href" @click="navigate" class="nav-link-container">
      <div class="nav-link-layout"
           :class="[isActive && 'router-link-active', isExactActive && 'router-link-exact-active']">
        <span class="nav-link-text"
              :style="isActiveLink(isActive, isExactActive) &&
               `color: ${activeColor};font-weight: ${activeWeight}`"><slot></slot></span>
      </div>
    </a>
  </router-link>
</template>

<script>
export default {
  props: {
    to: String,
    icon: String,
    activeIcon: String,
    exact: Boolean,
    activeColor: {
      type: String,
      default: '#e2136e'
    },
    activeWeight: {
      type: Number,
      default: 500
    }
  },
  methods: {
    isActiveLink(isActive, isExactActive) {
      if (this.exact) {
        return isActive && isExactActive;
      } else {
        return isActive;
      }
    }
  }
}
</script>

<style scoped>
.nav-link-layout {
  display: flex;
  flex-direction: row;
  color: #464646;
}

.nav-link-container {
  margin-right: 60px;
}

.nav-link-layout img {
  margin-right: 10px;
  width: 16px;
  height: 16px;
}

.nav-link-text {
  font-size: 18px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: left;
  color: #777777;
}

@media screen and (max-width: 1124px) {
  .nav-link-text {
    font-size: 14px;
  }
}

@media screen and (min-width: 1500px) {
  .nav-link-container {
    margin-right: 100px;
  }
}

@media screen and (max-width: 1250px) {

  .nav-link-container {
    margin-right: 0;
  }
}
</style>