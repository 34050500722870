<template>
  <v-form>
    <div class="logo_form">
      <span class="logo_form_title">Upload Logo</span>

      <div v-if="!logo.image">
        <!-- No image has been selected -->
        <img class="logo_icon" :src="pgwLogo" alt="">
      </div>
      <div v-else>
        <!-- An image has been selected -->
        <img class="logo_icon" :src="url" alt="">
      </div>

      <v-file-input
          id="fileSelector" v-model="logo.image" ref="fileSelector"
          show-size accept=".png" class="d-none"
          @change="previewImage">
      </v-file-input>

      <div class="error_div" v-if="logoDimensionError || logoTypeError || (logoSize > logoSizeThresholdInBytes)">
        <span
            class="size_error_text">
          Logo must meet the given requirements.
        </span>
      </div>

      <div class="button-container" style="margin-top: 25px;">
        <v-btn @click="manuallyOpenFileSelector"
               color="#f3f3f3" style="color: #e2136e; padding: 0px 24px;" elevation="0">
          <v-icon style="margin-right: 8px">mdi-attachment</v-icon>
          Upload File
        </v-btn>
      </div>

      <div class="button-container" style="margin-top: 23px; margin-bottom: 55px;">
        <v-btn color="white" style="color: #d0021b; margin-right: 8px;"
               elevation="0"
               @click="cancelUpload">Cancel
        </v-btn>

        <v-btn color="#e2136e" style="color: #FFFFFF;  padding: 0px 24px;" elevation="0"
               :disabled="isDisabled"
               @click="uploadLogo">Publish
        </v-btn>
      </div>

      <div class="logo-upload-requirements">
        Logo must meet the following requirements:<br/>
        Dimension: 128x128 px<br/>
        Format: .png<br/>
        Size: Within 10KB
      </div>

      <div class="logo-upload-warning-note">
        Uploading any inappropriate image will result in immediate revoke.
      </div>

    </div>
  </v-form>
</template>

<script>

export default {
  name: "LogoUpload",
  props: ['notifyDialogClose', "isVisible"],
  data() {
    return {
      logoSizeThresholdInBytes: 10000,
      url: "",
      logo: {
        image: "",
        extension: "",
        mimeType: "",
      },
      logoSize: 0,
      logoHeight: 0,
      logoWidth: 0,
    }
  },

  watch: {
    // NOTE: This is necessary as we need to detect when the user
    // dismisses the non-persistent dialog without pressing the cancel button
    isVisible: {
      immediate: true,
      handler() {
        if (!this.isVisible) {
          this.resetAll();
        }
      }
    }
  },

  computed: {
    isDisabled() {
      return !this.logo.image || this.logoSize > 10000 || this.logoHeight != 128 || this.logoWidth != 128 || this.logo.extension !== 'png';
    },
    logoTypeError() {
      return this.logo.extension !== 'png' && this.logo.extension !== '';
    },
    logoDimensionError() {
      return this.logoSize != 0 && (this.logoHeight !== 128 || this.logoWidth !== 128);
    },
    pgwLogo() {
      return this.$store.getters.getPGWLogo;
    },
  },
  methods: {
    manuallyOpenFileSelector() {
      let el = this.$refs.fileSelector.$refs.input;
      el.click();
    },
    cancelUpload() {
      this.notifyDialogClose();
      this.resetAll();
    },

    resetAll() {
      this.url = null;
      this.logoSize = 0;
      this.logoHeight = 0;
      this.logoWidth = 0;
      this.logo = {
        image: "",
        extension: "",
        mimeType: "",
      }
    },

    uploadLogo() {
      this.$feedback.showLoading();
      this.$cppClient.post('/logo/upload', this.logo)
          .then(res => {
            this.$feedback.hideLoading();

            // Append a unique link to force re-draw
            this.$store.commit('setPGWLogo', res.data.image + "?t=" + new Date().getTime());

            this.resetAll();
            this.notifyDialogClose();

            this.$feedback.showSuccessMessage('Your logo has been uploaded.');
          })
          .catch(({response}) => {
            this.$feedback.showFailed(response);
            this.resetAll();
            this.notifyDialogClose();
          });
    },

    previewImage() {
      this.logoSize = event.target.files[0].size;
      this.url = URL.createObjectURL(this.logo.image);
      this.logo.mimeType = this.logo.image.type;

      let image = new Image();
      image.src = URL.createObjectURL(event.target.files[0]);

      let app = this;
      //Validate the File Height and Width.
      image.onload = function () {
        app.logoHeight = this.height;
        app.logoWidth = this.width;
      }
      this.logo.extension = this.logo.image.name.split('.').pop().toLowerCase();
      var reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = () => {
        this.logo.image = reader.result.split(",").pop();
      };
    },
  }
}
</script>

<style scoped>
.logo_form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.logo_text {
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.28;
  letter-spacing: normal;
  text-align: center;
  color: #464646;
}

.size_error_text {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  padding-right: 15px;
  padding-left: 15px;
  line-height: 1.28;
  letter-spacing: normal;
  text-align: left;
  color: #d0021b;
}

.circular-logo {
  border-radius: 50%;
}

.logo_form_title {
  font-size: 22px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.82;
  letter-spacing: normal;
  text-align: center;
  margin-top: 40px;
  margin-bottom: 20px;
  color: #464646
}

.logo_icon {
  width: 75px;
  height: 75px;
}

.error_div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 5px
}

.logo-upload-requirements {
  color: #777777;
  text-align: center;
}

.logo-upload-warning-note {
  text-align: center;
  font-size: 15px;
  color: #aaaaaa;
  margin: 30px 10px;
}
</style>
