<template>
  <v-app-bar elevation="0" height="76" fixed color="#ffffff" class="navbar">
    <div class="header-body">
      <div class="header-icon-container">
        <router-link to="/">
          <img class="header-bkash" src="@/assets/image/header-bkash-icon.png">
        </router-link>
        <div class="vl"></div>
        <p class="header-text" id="v-step-9">Business Dashboard</p>
      </div>
      <section class="header-menu">
        <div class="nav-list">
          <nav-link to="/dashboard" exact>Home</nav-link>
          <nav-link to="/payment-links" id="v-step-4">Payment Links</nav-link>
          <nav-link to="/transaction-report" id="v-step-3">Transactions</nav-link>
          <nav-link to="/order-management">Orders</nav-link>
          <nav-link to="/merchant-quest" v-if="isMerchantOnMerchantQuest">Quests</nav-link>
          <nav-link to="/referandearn">Referrals</nav-link>

          <!--TODO: make a vue component for this kind of nav links-->
          <!-- <v-menu bottom
                  origin="center center"
                  transition="scale-y-transition"
                  offset-y>
            <template v-slot:activator="{ on, attrs }">
              <div class="merchant-management-dropdown nav-link-text" id="v-step-5"
                   v-bind="attrs"
                   v-on="on">
                <span :class="{'link-active': isTransferActive}" class="nav-link-text">Services</span>
              </div>
            </template>
            <v-list>
              <router-link class="link"
                           exact-active-class="link-active"
                           :to="{name: 'send-money'}"
              >
                <v-list-item>
                  <v-list-item-title class="nav-link-text">
                    Send Money
                  </v-list-item-title>
                </v-list-item>
              </router-link>
              <router-link class="link"
                           exact-active-class="link-active"
                           :to="{name: 'payment'}"
              >
                <v-list-item>
                  <v-list-item-title class="nav-link-text">
                    Merchant Payment
                  </v-list-item-title>
                </v-list-item>
              </router-link>
              <router-link class="link"
                           exact-active-class="link-active"
                           :to="{name: 'refund'}"
              >
                <v-list-item>
                  <v-list-item-title class="nav-link-text">
                    Refund
                  </v-list-item-title>
                </v-list-item>
              </router-link>
            </v-list>
          </v-menu> -->
        </div>

        <div style="display: flex; align-items: center;">
          <inbox></inbox>

          <v-menu bottom
                  origin="center center"
                  transition="scale-y-transition"
                  offset-y>
            <template v-slot:activator="{ on, attrs }">
              <div class="user-layout" id="v-step-7"
                  v-bind="attrs"
                  v-on="on">
                <div class="circle" :class="{ 'circle-no-fill': pgwLogo }">
                  <img class="logo-as-icon" :src="pgwLogo" alt="">
                </div>
                <v-icon size="30" left>mdi-menu-down</v-icon>
              </div>
            </template>

            <v-list>
              <v-list-item @click="initChangeLogo">
                <v-list-item-title>Change Logo</v-list-item-title>
              </v-list-item>
              <v-list-item @click="goToAccountInfo">
                <v-list-item-title>Account Info</v-list-item-title>
              </v-list-item>
              <v-list-item @click="goToChangePassword">
                <v-list-item-title>Change Password</v-list-item-title>
              </v-list-item>
              <v-list-item @click="showReleaseNoteModal">
                <v-list-item-title>Release Note</v-list-item-title>
              </v-list-item>
              <v-list-item :to="'/faq-support'">
                <v-list-item-title>FAQ &amp; Support</v-list-item-title>
              </v-list-item>
              <v-list-item @click="handleLogout">
                <v-list-item-title>Logout</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </section>
    </div>
  </v-app-bar>
</template>

<script>
import NavLink from "@/components/common/NavLink";
import Inbox from "../inbox/Inbox.vue";

export default {
  name: "WebNavBar",
  data() {
    return {
      isInfoActive: false,
      isTransferActive: false,
    }
  },
  components: {
    NavLink,
    Inbox
  },
  computed: {
    pgwLogo() {
      return this.$store.getters.getPGWLogo;
    },
    userName() {
      return this.$store.getters.getUserName;
    },
    releaseNote() {
      return this.$store.getters.getReleaseNote;
    },
    facebookGroupLink() {
      return process.env.VUE_APP_FACEBOOK_GROUP_URL || '';
    },
    isMerchantOnMerchantQuest() {
      return this.$store.getters.getMerchantOnMerchantQuest;
    }
  },
  methods: {
    handleLogout() {
      this.$cppClient.post('logout', {
        idToken: this.$store.getters.getIdToken
      }).then(() => {
        this.$store.commit('clearPageState');
        this.$store.commit('logoutUser')
        this.$router.push({name: 'sign-in'});
      }).catch(() => {
        this.$store.commit('clearPageState');
        this.$store.commit('logoutUser')
        this.$router.push({name: 'sign-in'});
      })
    },
    goToChangePassword() {
      this.$router.push('/change-password');
    },
    goToAccountInfo() {
      this.$router.push('/account-info');
    },
    toLimitsFees() {
      this.$router.push({name: 'ac-limits-fees'});
    },
    toFaqSupport() {
      this.$router.push({name: 'faq-support'});
    },
    initChangeLogo() {
      this.$store.commit("setShowLogoChangeDialog", true);
    },
    showReleaseNoteModal() {
      if (this.releaseNote.isPublished && this.releaseNote.featureList && this.releaseNote.featureList.length > 0) {
        return this.$eventBus.$emit('showReleaseNoteDialog');
      }

      this.$feedback.showSubtleErrorToast("Nothing new to show !");
    },
  },
  watch: {
    $route(to) {
      this.isInfoActive = to.name === 'ac-limits-fees' || to.name === 'faq-support';
      this.isTransferActive = to.name === 'payout' || to.name === 'refund';
    }
  },
}
</script>

<style scoped>

.navbar {
  z-index: 190;
}

.header-body {
  display: flex;
  width: 100%;
  height: 76px;
  align-items: center;
  justify-content: space-between;
  border-radius: 4px;
}

.header-icon-container {
  display: flex;
  align-items: center;
  margin-left: 14px;
}

.vl {
  margin-left: 8px;
  background-color: #DEDEDE;
  height: 25px;
  margin-right: 11px;
}

.header-text {
  font-size: 14px;
  font-weight: normal;
  color: #A7A7A7;
  margin-bottom: 0;
}

.nav-link-text {
  font-size: 18px;
  font-weight: 400;
  color: #777777;;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: left;
}

.header-bkash {
  width: 98px;
  height: 45px;
}

.header-menu {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 75%;
}

.nav-list {
  display: flex;
  align-items: center;
  width: 85%;
}

.user-layout {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-self: center;
}

.merchant-management-dropdown {
  display: flex;
  flex-direction: row;
  color: #464646;
  align-items: center;
}

.merchant-management-dropdown img {
  margin-right: 10px;
}

.merchant-management-dropdown > img {
  margin-right: 10px;
  width: 16px;
  height: 16px;
}

.link {
  color: #464646;
}

.link-active {
  color: #e2136e !important;
}

.link-active .v-list-item__title {
  color: #e2136e !important;
}

.v-menu__content,
.v-menu__content--fixed {
  top: 60px !important;
  z-index: 99999 !important;
}


.v-application--is-ltr .v-list-item__action:first-child, .v-application--is-ltr .v-list-item__icon:first-child {
  margin-right: 5px !important;
}

.v-list-item__icon {
  margin: 12px 0;
}

.logo-as-icon {
  width: 32px;
  height: 32px;
  border-radius: 50%;
}


@media screen and (min-width: 1600px) {
  .header-menu {
    width: 81%;
  }
}

@media screen and (max-width: 1250px) {

  .nav-list {
    justify-content: space-between;
  }
}

@media screen and (max-width: 1124px) {
  .header-menu {
    width: 71%;
  }

  .nav-link-text {
    font-size: 14px;
  }
}
</style>